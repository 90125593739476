/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body.is-embed {
  .head {
    .btn {
      display: none!important;
    }
  }
  ion-menu-toggle, ion-menu, app-tabs {
    display: none;
  }
}

ion-menu::part(container) {
  justify-content: flex-start;
  --background: linear-gradient(135deg, rgb(3, 56, 238) 0%, rgb(13, 56, 238) 8%, rgb(110, 56, 239) 35%, rgb(165, 56, 239) 60%, rgb(200, 56, 239) 90%, rgb(213, 56, 240) 100%);
  padding: 50px 20px;
  padding-bottom: 100px;
  height: auto;
  overflow-y: auto;
}

ion-menu {
  .title {
    color: #FFF;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 18px;
    padding-left: 20px;
  }
  ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 30px;
    ion-menu-toggle {
      margin: 15px 0px;
      width: 100%;
      display: flex;
      a {
        color: #FFF;
        font-size: 18px;
        font-weight: 400;
        width: 100%;
        text-decoration: none;
        &.active {
          font-weight: 700;
        }
      }
    }
  }
}


ion-toast.error-toast {
  --background: #dc3545;
  --color: #FFF;
}
ion-toast.success-toast {
  --background: #28a745;
  --color: #FFF;
}


input[type=number] { 
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0; 
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
}

.vimeo-player {
  iframe {
    width: 100%;
    height: 100%;
  }
}

iframe {
  max-width: 100%;
}

.swiper-pagination-bullet-active {
  background: #FFF;
}
.swiper-pagination-bullet {
  background: #CCC;
  margin: 0px 3px;
}

.modalProblem{
  --width: 800px;
  --height: 1000px;
  --border-radius: 20px;

  @media screen and (max-width: 768px){
    --width: 100%;
    --height: 100%;
    --border-radius: 0px;
  }
  .problemContent{
    --padding-top: 70px !important;
    --padding-bottom: 20px !important;
    --padding-start: 50px !important;
    --padding-end: 50px !important;

    @media screen and (max-width: 768px){
      --padding-top: 70px !important;
      --padding-bottom: 20px !important;
      --padding-start: 30px !important;
      --padding-end: 30px !important;
    }
  }
}